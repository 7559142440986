@font-face {
  font-family: NeueMachina;
  src: url("./assets/fonts/PPNeueMachina-PlainRegular.otf");
}

@font-face {
  font-family: NeueMachinaLight;
  src: url("./assets/fonts/PPNeueMachina-PlainLight.otf");
}

@font-face {
  font-family: NeueMachinaBold;
  src: url("./assets/fonts/PPNeueMachina-PlainUltrabold.otf");
}

*::selection{
  background-color: #F8786B;
  color: #051C2C;
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'NeueMachina';
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #F2F3F5;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #0F0A0B;
}

input, select, textarea, option, button { font-family: 'NeueMachina';}

input:focus, input:active, select:focus, button:focus{
  outline: none;
}
