.modal-overlay{
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  background-color: #051C2C2D;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 999;
}
