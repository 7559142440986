div.mask{
  height: 15px;
  width: 15px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #F8786B;
  border-radius: 50%;
  z-index: 9999;
  pointer-events: none;
}

@media (max-width: 1024px) {
  div.mask{
    display: none;
  }
}
